// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-ts": () => import("./../../../src/pages/about.ts" /* webpackChunkName: "component---src-pages-about-ts" */),
  "component---src-pages-contact-form-success-tsx": () => import("./../../../src/pages/contact-form-success.tsx" /* webpackChunkName: "component---src-pages-contact-form-success-tsx" */),
  "component---src-pages-index-ts": () => import("./../../../src/pages/index.ts" /* webpackChunkName: "component---src-pages-index-ts" */),
  "component---src-pages-services-ts": () => import("./../../../src/pages/services.ts" /* webpackChunkName: "component---src-pages-services-ts" */)
}

